import { useAxios } from '../axiosUtil'
import QueryStringBuilder from '@/util/queryStringBuilder'

const token = localStorage.getItem('token')
const tokenType = localStorage.getItem('tokenType')
const headerOpt = (method) => ({
  method,
  headers: {
    Authorization: `${tokenType} ${token}`
  }
})

const baseUrl = '/v1/board/lab-gallery'

export const getGallery = async (arg) => {
  // 전체 조회
  if (!arg) return await useAxios(`${baseUrl}`)

  // 한 건 조회
  if (typeof arg !== 'object') return await useAxios(`${baseUrl}/${arg}`)

  // 페이지네이션
  const obj = {
    limit: 1000,
    offset: 0,
    ...arg
  }
  return await useAxios(`${baseUrl}/${QueryStringBuilder(obj)}`)
}

const gallery = async (method, data, id) => {
  let url = baseUrl
  const opt = headerOpt(method)

  if (method === 'POST') {
    opt.data = data
  } else if (method === 'PUT') {
    url += `/${id}`
    opt.data = data
  } else if (method === 'DELETE') {
    url += `/${data}`
  }

  return await useAxios(url, opt)
}

export const insertGallery = async (data) => {
  return gallery('POST', data)
}
export const updateGallery = async (data, id) => {
  return gallery('PUT', data, id)
}
export const deleteGallery = async (id) => {
  return gallery('DELETE', id)
}
